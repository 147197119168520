import {
	IGetFilteredOpportunities,
	IGetOpportunitiesByFilter,
	IGetOpportunitiesByName,
	IModification,
	IReproveOpportunityRequest,
	IResponse,
	IResponseOpportunities,
} from "@/utils/interfaces"

import { ref } from "vue"
import { useClient } from "@/clients/AxiosClient"
import { ICreateOpportunity } from "@/utils/interfaces"
import Cookies from "js-cookie"
import { createHistory } from "./history"
import { Types } from "@/utils/enum"
import { probabilityClosing } from "@/utils/maps"
import { ResultResponse } from "@/clients/Client"

export async function getFilteredOpportunities({
	amount,
	page,
	company,
	filters,
	order,
}: IGetFilteredOpportunities): Promise<IResponse> {
	try {
		const result = ref<IResponseOpportunities[]>([])
		const quantityPage = ref(1)

		const userData = Cookies.get("user_permissions")

		let base: string
		let createdAt
		let estimated

		if (!userData) {
			throw new Error("User permission not found")
		}

		const userDataArray = Array.from(JSON.parse(userData))

		enum ApiRequest {
			RESALE = `/opportunity/resale`,
			SELLER = `/opportunity/user`,
			REPRESENTATIVE = `/opportunity/all`,
		}

		if (userDataArray.includes("RESALE")) {
			base = ApiRequest.RESALE
		} else if (userDataArray.includes("SELLER")) {
			base = ApiRequest.SELLER
		} else if (
			userDataArray.includes("REPRESENTATIVE") ||
			userDataArray.includes("SUPPORT")
		) {
			base = ApiRequest.REPRESENTATIVE
		} else {
			throw new Error("Permission denied")
		}

		if (order.item === "createdAt") {
			createdAt = order.value
		} else if (order.item === "estimated") {
			estimated = order.value
		}

		const response = await useClient().get(
			`${base}?amount=${amount}&brandName=${company}&page=${page}&createdAt=${createdAt}&estimated=${estimated}&status=${filters?.status}&state=${filters?.state}&city=${filters?.city}&cnpj=${filters?.cnpj}&email=${filters?.email}&name=${filters?.name}&client=${filters?.fantasyName}&date=${filters?.date}`
		)

		if (response.status >= 400) {
			throw new Error(response.data.message)
		}

		result.value = response.data.data.result
		const total = response.data.data.total

		quantityPage.value = Math.ceil(total / amount)

		// return {
		// 	filteredResult: result.value.slice(start, end),
		// 	data: result.value,
		// 	quantityPage: quantityPage.value,
		// }
		return {
			data: result.value,
			quantityPage: quantityPage.value,
		}
	} catch (err: any) {
		return err
	}
}

export async function getOpportunitiesByName({
	name,
	company,
}: IGetOpportunitiesByName): Promise<IResponse> {
	try {
		const result = ref<IResponseOpportunities[]>([])
		const quantityPage = ref(1)

		const response = await useClient().get(
			`/opportunity/name?company=${company}&name=${name}`
		)
		if (response.status >= 400) {
			throw new Error(response.data.message)
		}

		result.value = response.data.data

		return {
			data: result.value,
			quantityPage: quantityPage.value,
		}
	} catch (err: any) {
		return err
	}
}

export async function getOpportunitiesByFilter({
	filter,
	value,
	page,
}: IGetOpportunitiesByFilter): Promise<IResponse> {
	try {
		const result = ref<IResponseOpportunities[]>([])
		const quantityPage = ref(1)

		const response = await useClient().get(
			`/opportunity/filter?filter=${filter}&value=${value}&amount=${5}&page=${page}`
		)

		if (response.status >= 400) {
			throw new Error(response.data.message)
		}

		result.value = response.data.data
		quantityPage.value = Math.ceil(response.data.data.total / 5)
		return {
			data: result.value,
			quantityPage: quantityPage.value,
		}
	} catch (err: any) {
		return err
	}
}

export async function createOpportunity(
	newOpportunityData: ICreateOpportunity
) {
	const result = await useClient().post(
		"/opportunity/create",
		{
			...newOpportunityData,
		},
		{}
	)

	return result
}

export async function getOpportunity(id: string) {
	const response = await useClient().get(`/opportunity/id/${id}`)
	if (response.status >= 400) {
		throw new Error(response.data.message)
	}
	return response
}

export async function approveOpportunity(
	id: string,
	status: string,
	oldOpportunityName: string,
	name?: string,
	newProb?: string,
	oldProb?: string
) {
	const userId = JSON.parse(Cookies.get("userid") || "")
	const username = JSON.parse(Cookies.get("username") || "")

	const modifications: Array<IModification> = []

	if (oldOpportunityName !== name) {
		modifications.push({
			attribute: "Nome",
			oldValue: oldOpportunityName,
			newValue: name,
		})
	}

	if (newProb !== oldProb) {
		modifications.push({
			attribute: "Situação",
			oldValue:
				probabilityClosing.find((value: any) => oldProb === value.key)?.value ||
				"N/C",
			newValue:
				probabilityClosing.find((value: any) => newProb === value.key)?.value ||
				"N/C",
		})
	}

	modifications.push({
		attribute: "Status",
		oldValue: "Análise",
		newValue: "Aprovada",
	})

	const response = await useClient().patch(
		`/opportunity/${id}`,
		{
			status,
			name,
			probabilityClosing: newProb,
		},
		{}
	)
	if (response.status == 423) {
		return response
	} else if (response.status >= 400) {
		throw new Error(response.data.message)
	} else {
		createHistory({
			typeId: id,
			username: username,
			userId: userId,
			type: Types.OPPORTUNITY,
			actionType: Types.UPDATE,
			modifications: modifications,
		})
	}

	return response
}

export async function reproveOpportunity({
	id,
	status,
	reason,
	name,
	oldReason,
	oldName,
	newProb,
	oldProb,
}: IReproveOpportunityRequest) {
	const userId = JSON.parse(Cookies.get("userid") || "")
	const username = JSON.parse(Cookies.get("username") || "")

	const modifications: Array<IModification> = []

	if (oldName !== name) {
		modifications.push({
			attribute: "Nome",
			oldValue: oldName,
			newValue: name,
		})
	}

	if (newProb !== oldProb) {
		modifications.push({
			attribute: "Nome",
			oldValue:
				probabilityClosing.find((value: any) => oldProb === value.key)?.value ||
				"N/C",
			newValue:
				probabilityClosing.find((value: any) => newProb === value.key)?.value ||
				"N/C",
		})
	}

	if (oldReason !== reason) {
		modifications.push({
			attribute: "Motivo",
			oldValue: oldReason,
			newValue: reason,
		})
	}

	modifications.push({
		attribute: "Status",
		oldValue: "Análise",
		newValue: "Reprovado",
	})

	const response = await useClient().patch(
		`/opportunity/${id}`,
		{
			status,
			reason,
			name,
			probabilityClosing: newProb,
		},
		{}
	)
	if (response.status >= 400) {
		throw new Error(response.data.message)
	} else {
		createHistory({
			typeId: id,
			username: username,
			userId: userId,
			type: Types.OPPORTUNITY,
			actionType: Types.UPDATE,
			modifications: modifications,
		})
	}

	return response
}

export async function getSellersOnSalesforce(): Promise<ResultResponse> {
	try {
		const response = await useClient().get(
			"/opportunity/getSellersOnSalesforce"
		)

		if (response.status >= 400) {
			return {
				status: response.status || 500,
				data: response,
			}
		}

		return response
	} catch (e: any) {
		return {
			status: e.status || 500,
			data: e,
		}
	}
}
